<template>
  <div class="finish__text">
    <h4>Вы уже являетесь участником программы лояльности.</h4>
  </div>
</template>

<script>
export default {
  name: "CardAlreadyRegistered",
}
</script>

<style lang="stylus">
.finish__text {
  text-align center
  white-space pre-line

  h4 {
    margin 0
  }
}
</style>

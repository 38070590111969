<template>
  <main class="main reg">
    <section class="section">
      <div class="container">
        <div class="reg__content">
          <component :is="activeStep.component" @changeStep="setActive" v-if="activeStep" :codes="codes"></component>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BeginRegistration from "./components/BeginRegistration";
import PhoneConfirm from "./components/PhoneConfirm";
import EmailConfirm from "./components/EmailConfirm";
import CardBinding from "./components/CardBinding";
import PasswordChange from "./components/PasswordChange";
import QuestionsComponent from "./components/QuestionsComponent";
import FinishRegistration from "./components/FinishRegistration";
import ContinueRegistration from "./components/СontinueRegistration";
import CardAlreadyRegistered from "./components/CardAlreadyRegistered";

export default {
  name: "Home",
  data() {
    return {
      activeStepCode: this.$store.state.codes.registration.BEGIN_REGISTRATION_CODE,
    }
  },
  // beforeCreate() {
  //   // получение шагов регистрации
  //   this.$store.dispatch('REGISTRATION_ACTIONS', { token: localStorage.getItem("auth-token") })
  //       .then(() => {
  //         // возвращение к тому шагу, на котором остановился пользователь
  //         // пока не работает нормально, потому что steps и actions не совпадают,
  //         // и изначально может перекидывать на неправильный шаг, т.к. он не завершен,
  //         // но он не является первым
  //         for (let item of this.$store.state.registration_actions) {
  //           if (!item.isDone && item.actionState === "Required" && this.findAction(item)) {
  //             this.setActive(this.findAction(item).code)
  //             return
  //           }
  //         }
  //       })
  // },
  created() {
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("card")) {
        sessionStorage.setItem("card", urlParams.get("card"))
      }
    }, 0)
  },
  computed: {
    steps() {
      return [
        {
          component: BeginRegistration,
          code: this.codes.BEGIN_REGISTRATION_CODE,
          actionType: "BeginRegistration",
        },
        {
          component: ContinueRegistration,
          code: this.codes.CONTINUE_REGISTRATION,
          actionType: "ContinueRegistration",
        },
        {
          component: PhoneConfirm,
          code: this.codes.PHONE_CONFIRM_CODE,
          actionType: "PhoneConfirm",
        },
        {
          component: PasswordChange,
          code: this.codes.PASSWORD_CHANGE_CODE,
          actionType: "PasswordRequired",
        },
        {
          component: QuestionsComponent,
          code: this.codes.QUESTIONS_CODE,
          actionType: "Questions",
        },
        {
          component: FinishRegistration,
          code: this.codes.FINISH_CODE,
          actionType: "Finish",
        },
        {
          component: CardAlreadyRegistered,
          code: this.codes.CARD_ALREADY_REGISTERED,
          actionType: "CardAlreadyRegistered",
        }

      ]
    },
    activeStep() {
      return this.steps.find(item => item.code === this.activeStepCode)
    },
    codes() {
      return this.$store.state.codes.registration
    }
  },
  methods: {
    findAction(action) {
      return this.steps.find(item => item.actionType === action.userActionType)
    },
    setActive(code) {
      this.activeStepCode = code
    }
  },
}
</script>

<style lang="stylus">
.reg {
  justify-content center

  &__content {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 42px 24px;
    background: var(--color_white);
    border-radius: 10px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
    +below(420px) {
      padding 20px
    }
  }
}
</style>